<footer class="main-footer" *ngIf="showSection">
  <div class="container-dynamic d-flex" fxHide.gt-xs="true">
		<span>
			Install SIMA
		</span>
		<span class="m-auto"></span>
		<a mat-raised-button color="accent" (click)="checkInstall()">Install</a>
    <!-- Design & Developed by <a href="https://ui-lib.com"> UI Lib</a> -->
  </div>
</footer>
