import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { EChartOption } from 'echarts';
import * as echarts from 'echarts';
import { HttpService } from 'app/shared/services/http.service';
import { UtilService } from 'app/shared/services/util.service';
import { ApiConfig } from 'app/configs/api.config';
import { JHCords } from 'app/configs/data.config';
import { GroupByPipe } from 'ngx-pipes';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';

@Component({
	selector: 'app-gis-report',
	templateUrl: './gis-report.component.html',
	styleUrls: ['./gis-report.component.scss']
})
export class GisReportComponent implements OnInit, OnChanges {

	userDetails: any = {};
	@Input('geoData') geoData: any = {};
	geoChart: any;
	chartOption: EChartOption = {};
	geoOption: any = {};
	DEFAULT_ZOOM = 1;
	mapZoom = 1;
	maxZoom = 30;
	minZoom = 1;
	zoomLevel = 1;

	selectedStack: any = new Map();
	defaultGeoData = [];
	jhJSON: any = {};
	ogJHJSON: any = {};

	selectedGeoType = 'pla';
	selectedGeoTitle = 'PLA Coverage';
	selectedGeoLegend = 'No. of \n PLA Meetings';

	plaGeoData = [];
	childGeoData = [];
	hvGeoData = [];

	selectedBlockIndex: any;
	selectedBlock: any;
	selectedClusterIndex: any;
	selectedCluster: any;
	selectedGeoData: any;
	selectedDistrictIndex: number;
	selectedDistrict: any;
	legend : any = [];
	constructor(private http: HttpService, private utility: UtilService, private groupBy: GroupByPipe, private jwt: JwtAuthService) { }

	ngOnChanges(changes: SimpleChanges) {
		console.log('changes', changes);
		this.geoData = changes.geoData.currentValue;
		if (this.geoChart) {
			this.geoChart.showLoading();
			this.onChartInit(this.geoChart);
		}
		// You can also use geoData.previousValue and 
		// geoData.firstChange for comparing old and new values

	}

	ngOnInit(): void {
		console.log('passed geoData', this.geoData);

		this.userDetails = this.jwt.getUser();
		console.log('userDetails', this.userDetails);
	}

	onChartInit(ec) {
		console.log('chart init', ec);
		console.log('groupBy', this.groupBy);
		this.geoChart = ec;
		let filterData = [];
		// let filterData = [{ 'resourceType': 'state', 'resourceId': 10 }];
		// let filterData = [{ 'resourceType': 'dist', 'resourceId': 103 }];
		this.getGeoData(filterData, true);
	}

	resizeChart() {
		if (this.geoChart) {
			this.geoChart.resize();
		}
	}


	getUserMapURL() {
		let url = './assets/geo/jh-state.json';
		let fileName = 'jh';
		// return url;

		if (this.geoData.selected) {
			if (this.geoData.selected.cluster && this.geoData.selected.cluster.id != 'All') {
				// cluster
				// fileName = (this.userDetails.attributes.districtName || 'westsinghbhum');
				fileName = this.geoData.selected.dist.name.toLowerCase().replace(' ', '');
				// return url;
			} else {
				if (this.geoData.selected.block && this.geoData.selected.block.id != 'All') {
					// block
					fileName = this.geoData.selected.dist.name.toLowerCase().replace(' ', '');
					// return url;
				} else {
					if (this.geoData.selected.dist && this.geoData.selected.dist.id != 'All') {
						// distirct
						fileName = this.geoData.selected.dist.name.toLowerCase().replace(' ', '');
						// return url;
					} else {
						// state
						// obj = {
						// 	"resourceType": "state",
						// 	"resourceId": "xxxx"
						// };
						// return null;
					}
				}
			}
		}

		let fileType = this.jwt.getRoleAccess(true);
		// let fileType: any = 'state';
		// if (type == 'dist') {
		// 	fileType = 'block';
		// } else if (type == 'level2') {
		// 	fileType = 'cluster';
		// } else if (type == 'level3') {
		// 	fileType = 'village';
		// }

		url = './assets/geo/' + fileName + '-' + fileType + '.geojson';

		return url;
	}


	getZoomLevel(type) {

		let access = this.jwt.getRoleAccess();

		// role
		if (access == 'state' || access == 'admin') {
			if (type == 'dist') {
				return 4;
			} else if (type == 'level2') {
				return 8;
			} else if (type == 'level3') {
				return 16;
			}
		}
		if (access == 'dist') {
			if (type == 'level2') {
				return 4;
			} else if (type == 'level3') {
				return 8;
			}
		}
		if (access == 'block') {
			if (type == 'level3') {
				return 4;
			}
		}

		if (access == 'cluster') {
			return 4;
		}

		return this.DEFAULT_ZOOM;
	}

	initGeo() {

		// this.geoChart = echarts.init(document.getElementById('geoChart'));

		// if (this.geoData.type == 'pla') {
		// 	this.defaultGeoData = this.plaGeoData;
		// }
		// if (this.geoData.type == 'hv') {
		// 	this.defaultGeoData = this.hvGeoData;
		// }
		// if (this.geoData.type == 'child') {
		// 	this.defaultGeoData = this.childGeoData;
		// }
		this.defaultGeoData = [];

		// this.jwt.getRoleAccess();
		// this.userDetails.id;

		const url = this.getUserMapURL();
		this.geoChart.showLoading();
		this.http.get(url).subscribe((geoJson: any) => {
			console.log('Load geoJson', geoJson);
			let type = this.jwt.getRoleAccess();

			let cordList = [];
			if (this.geoData.selected) {
				if (this.geoData.selected.cluster && this.geoData.selected.cluster.id != 'All') {
					// cluster
					let name = this.geoData.selected.cluster.name;
					name = name.toUpperCase();
					cordList = geoJson.features.filter(b => b.properties.cluster_na == name);
					console.log('cordList', cordList);
					geoJson.features = cordList;
				} else {
					if (this.geoData.selected.block && this.geoData.selected.block.id != 'All') {
						// block
						let name = this.geoData.selected.block.name;
						name = name.toUpperCase();
						cordList = geoJson.features.filter(b => b.properties.nhm_block == name);
						console.log('cordList', cordList);
						geoJson.features = cordList;
					} else {
						if (this.geoData.selected.dist && this.geoData.selected.dist.id != 'All') {
							// distirct
							let name = this.geoData.selected.dist.name;
							name = name.toUpperCase();
							cordList = geoJson.features.filter(b => b.properties.dtname == name);
							console.log('cordList', cordList);
							geoJson.features = cordList;
						} else {
							// state
							// obj = {
							// 	"resourceType": "state",
							// 	"resourceId": "xxxx"
							// };
							// return null;
						}
					}
				}
			}

			console.log('updated geoJson', geoJson);

			geoJson.features.forEach(d => {

				if (type == 'state' || type == 'admin') {
					// d.properties.ipname = d.properties.dtname;
					d.properties.name = d.properties.dtname;
					d.properties.name = d.properties.name.toUpperCase();
					d.properties.geoId = d.properties.dtcode;
					this.defaultGeoData.push(
						{ name: d.properties.name, value: 0, id: (d.properties.dtcensus11 || d.properties.dtcode) },
					);
				} else if (type == 'dist') {
					d.properties.name = d.properties.nhm_block;
					d.properties.name = d.properties.name.toUpperCase();
					d.properties.geoId = d.properties.nhm_code;
					this.defaultGeoData.push(
						{ name: d.properties.name, value: 0, id: d.properties.nhm_code },
					);
				} else if (type == 'block') {
					d.properties.name = d.properties.cluster_na;
					d.properties.name = d.properties.name.toUpperCase();
					d.properties.geoId = d.properties.cluster_co;
					this.defaultGeoData.push(
						{ name: d.properties.name, value: 0, id: d.properties.cluster_co },
					);
					// d.properties.change = true;
				} else if (type == 'cluster') {
					d.properties.name = d.properties.villname;
					d.properties.name = d.properties.name.toUpperCase();
					d.properties.geoId = d.properties.vlgcode;
					this.defaultGeoData.push(
						{ name: d.properties.name, value: 0, id: d.properties.vlgcode },
					);
					// d.properties.change = true;
				} else if (type == 'village') {
					// not possible
				}

			});

			this.jhJSON = geoJson;

			this.ogJHJSON = JSON.parse(JSON.stringify(geoJson));
			this.geoChart.hideLoading();
			echarts.registerMap('USERMAP', geoJson, {});
			this.geoOption = {
				title: {
					text: this.selectedGeoTitle,
					// subtext: 'Data from www.census.gov',
					// sublink: 'http://www.census.gov/popest/data/datasets.html',
					// left: 'left',
					top: 20,
					left: 20
				},
				// legend: {
				// 	show: true,
				// 	// data: ['Jharkhand Overview'],
				// },
				tooltip: {
					trigger: 'item',
					showDelay: 0,
					transitionDuration: 0.2,
					formatter: (params) => {
						const value = (params.value + '').split('.');
						// console.log('value',value);
						if (value.length && !Number.isNaN(value[0]) && value[0] != 'NaN') {
							// const valueStr = value[0];
							const valueStr = value[0].replace(
								/(\d{1,3})(?=(?:\d{3})+(?!\d))/g,
								'$1,'
							);
							return params.name + ': ' + valueStr;
						} else {
							return params.name;
						}
					}
				},
				visualMap: {
					type: 'piecewise',
					splitNumber: 3,
					left: 'right',
					// min: 500000,
					// max: 38000000,\
					inRange: {
						color: [
							// '#74add1',
							// '#4575b4',
							'#BEFC9C',
							'#6BC03C',
							'#2A8C1A',
							// '#f7f36c', // green
							// '#f5b420', // orange
							// '#37eb4e', // yellow
						]
					},
					text: [this.selectedGeoLegend],
					calculable: true,
					showLabel: true,
					right: '20',
					bottom: '20',
					pieces: this.legend
				},
				// toolbox: {
				// 	show: true,
				// 	//orient: 'vertical',
				// 	left: 'left',
				// 	top: 'bottom',
				// 	feature: {
				// 		dataView: { readOnly: false },
				// 		dataZoom: { show: true },
				// 		restore: {},
				// 		saveAsImage: {}
				// 	}
				// },
				animationDuration: 1000,
				animationEasing: 'cubicOut',
				animationDurationUpdate: 1000,
				series: [
					{
						name: 'Jharkhand Overview',
						type: 'map',
						roam: false,
						// TODO: id mapping need to be done
						nameProperty: 'name',
						map: 'USERMAP',
						aspectScale: 0.9,
						// projection: {
						// 		project: (point) => [point[0] / 180 * Math.PI, -Math.log(Math.tan((Math.PI / 2 + point[1] / 180 * Math.PI) / 2))],
						// 		unproject: (point) => [point[0] * 180 / Math.PI, 2 * 180 / Math.PI * Math.atan(Math.exp(point[1])) - 90]
						// },
						zoom: this.DEFAULT_ZOOM,
						scaleLimit: {
							min: this.minZoom,
							max: this.maxZoom
						},
						label: {
							show: false,
							fontSize: 9
						},
						itemStyle: {
							// normal: { label: { show: true } },
							emphasis: { label: { show: true, fontSize: 9 } }
						},
						data: this.defaultGeoData
					}
				]
			};

			if (type == 'state' || type == 'admin') {
				this.setCenterValue2(this.getJHCords());
			} else if (type == 'dist') {
				let params: any = {
					data: this.geoData.selected.dist,
				};
				params.data.name = params.data.name.toUpperCase();
				params.data.value = 0;
				this.selectedDistrict = params.data;
				// this.setCenterValue(params, cord);
			} else if (type == 'block') {
				let params: any = {
					data: this.geoData.selected.dist,
				};
				params.data.name = params.data.name.toUpperCase();
				params.data.value = 0;
				this.selectedBlock = params.data;
				// this.setCenterValue(params, cord);
			} else if (type == 'cluster') {
				let params: any = {
					data: this.geoData.selected.dist,
				};
				params.data.name = params.data.name.toUpperCase();
				params.data.value = 0;
				this.selectedCluster = params.data;
				// this.setCenterValue(params, cord);
			} else if (type == 'village') {
				// invalid
			} else {
				// invalid
			}

			this.geoChart.setOption(this.geoOption);


			this.selectedStack = new Map();
			this.geoChart.on('click', (params) => {
				console.log('click', params);
				// check type and append+zoom that map
				// dist = dist
				// level2 = block
				// level3 = cluster
				// village = village
				if (params.data) {
					if (params.data.type == 'dist') {

						this.selectedStack = new Map();

						// clear values
						this.selectedBlockIndex = undefined;
						this.selectedBlock = undefined;

						this.selectedClusterIndex = undefined;
						this.selectedCluster = undefined;

						// clear map
						this.jhJSON = JSON.parse(JSON.stringify(this.ogJHJSON));
						this.selectedGeoData = this.selectedGeoData.filter(g => {
							return !g.change;
						});

						this.selectedDistrictIndex = 0;
						this.selectedDistrict = params.data;
						const cord = this.jhJSON.features.filter((d, i) => {
							if (d.properties.name == params.data.name) {
								this.selectedDistrictIndex = i;
								return true;
							}
						})[0];

						this.selectedStack.set(params.data.type, {
							index: this.selectedDistrictIndex,
							data: this.selectedDistrict,
							type: params.data.type
						});

						// console.log('dist cord', cord);
						this.mapZoom = this.getZoomLevel(params.data.type);
						this.setCenterValue(params, cord);

					} else if (params.data.type == 'level2') {

						// clear
						this.selectedClusterIndex = undefined;
						this.selectedCluster = undefined;

						// clear map
						this.jhJSON.features = this.jhJSON.features.filter(g => {
							return !g.properties.change;
						});
						this.selectedGeoData = this.selectedGeoData.filter(g => {
							return !g.change;
						});

						// set removed data
						const stackData = this.selectedStack.get(params.data.type);
						if (stackData && stackData.geoData) {
							this.jhJSON.features.push(stackData.geoData);
						}
						if (this.selectedBlockIndex != undefined && this.selectedBlock) {
							this.selectedGeoData.push(this.selectedBlock);
						}

						this.selectedBlockIndex = 0;
						this.selectedBlock = params.data;
						const cord = this.jhJSON.features.filter((d, i) => {
							if (d.properties.name == params.data.name) {
								this.selectedBlockIndex = i;
								return true;
							}
						})[0];

						this.selectedStack.set(params.data.type, {
							index: this.selectedBlockIndex,
							data: this.selectedBlock,
							type: params.data.type
						});

						// console.log('block cord', cord);
						this.mapZoom = this.getZoomLevel(params.data.type);
						this.setCenterValue(params, cord);

					} else if (params.data.type == 'level3') {

						// clear map
						this.jhJSON.features = this.jhJSON.features.filter(g => {
							return !g.properties.subChange;
						})
						this.selectedGeoData = this.selectedGeoData.filter(g => {
							return !g.subChange;
						})

						// set removed data
						const stackData = this.selectedStack.get(params.data.type);
						if (stackData && stackData.geoData) {
							this.jhJSON.features.push(stackData.geoData);
						}
						if (this.selectedClusterIndex != undefined && this.selectedCluster) {
							this.selectedGeoData.push(this.selectedCluster);
						}

						this.selectedClusterIndex = 0;
						this.selectedCluster = params.data;
						const cord = this.jhJSON.features.filter((d, i) => {
							if (d.properties.name == params.data.name) {
								this.selectedClusterIndex = i;
								return true;
							}
						})[0];

						// if exist replace
						this.selectedStack.set(params.data.type, {
							index: this.selectedClusterIndex,
							data: this.selectedCluster,
							type: params.data.type
						});

						// console.log('cluster cord', cord);
						this.mapZoom = this.getZoomLevel(params.data.type);
						this.setCenterValue(params, cord);
					} else {
						console.log('do nothing', params.data.type);
					}
				}
			});

			this.geoChart.on('dblclick', (params) => {
				console.log('dblclick', params);
				// this.geoOption.series[/ 0].center = [0,0],
				this.geoOption.series[0].zoom = this.DEFAULT_ZOOM;
				this.geoChart.setOption(this.geoOption);
			});

			this.geoChart.on('geoselected', (params) => {
				console.log('geoselected', params);
			});

			setTimeout(() => {
				let dataList = [];
				if (this.geoData.type == 'pla') {
					dataList = this.plaGeoData;
				}
				if (this.geoData.type == 'hv') {
					dataList = this.hvGeoData;
				}
				if (this.geoData.type == 'child') {
					dataList = this.childGeoData;
				}

				// set zero value of non-existing data
				if (this.jhJSON && this.jhJSON.features) {
					this.jhJSON.features.forEach(d => {
						let exist = dataList.filter(d1 => {
							return d1.name && d1.name.toUpperCase().includes(d.properties.name);
						});
						if (exist && exist.length) {
							// do nothing
						} else {
							dataList.push({
								id: d.properties.geoId,
								name: d.properties.name,
								type: this.jwt.getRoleAccess(true, true),
								value: 0
							});
						}
					});
				}

				this.changeGeoData(this.geoData.type);
			}, 1000);
		});
	}

	getGeoData(filterData?, firstCall?, subType?) {
		console.log('getGeoData called', this.geoData);
		if (this.geoData && this.geoData.type) {

			let sendData = {
				'reportgroup': 'gisreport',
				'reportprogram': [this.geoData.type],
				'filters': filterData ?? []
			};

			let url = '';
			if (this.geoData.type == 'pla') {
				url = ApiConfig.pla.gis;
			}
			if (this.geoData.type == 'hv') {
				url = ApiConfig.homeVisit.gis;
			}
			if (this.geoData.type == 'child') {
				url = ApiConfig.newChild.gis;
			}

			this.http.post(url, sendData, { action: 'getReport' }).subscribe((respData: any) => {
				console.log('gis resp data', respData);

				if (firstCall) {
					this.plaGeoData = [];
					this.childGeoData = [];
					this.hvGeoData = [];

					respData.data.forEach(report => {

						// set zero value of non-existing data
						if (this.jhJSON && this.jhJSON.features){
							this.jhJSON.features.forEach(d => {
								let exist = report.data.filter(d1 => {
									return d1.name.toUpperCase().includes(d.properties.name);
								});
								if (exist && exist.length) {
									// do nothing
								} else {
									report.data.push({
										id: d.properties.geoId,
										name: d.properties.name,
										type: this.jwt.getRoleAccess(true, true),
										value: 0
									});
								}
							});
						}
						if (report.reportType == 'geoPLAData') {
							report.data.forEach(d => {
								d.name = d.name.toUpperCase();
								// d.ipname = d.name.toUpperCase();
								d.type = this.jwt.getRoleAccess(true, true);
							});
							this.plaGeoData = report.data;
							this.legend = report.legend
						}
						if (report.reportType == 'geoChildData') {
							report.data.forEach(d => {
								d.name = d.name.toUpperCase();
								// d.ipname = d.name.toUpperCase();
								d.type = this.jwt.getRoleAccess(true, true);
							});
							this.childGeoData = report.data;
							this.legend = report.legend
						}
						if (report.reportType == 'geoHVData') {
							report.data.forEach(d => {
								d.name = d.name.toUpperCase();
								// d.ipname = d.name.toUpperCase();
								d.type = this.jwt.getRoleAccess(true, true);
							});
							this.hvGeoData = report.data;
							this.legend = report.legend
						}
					});


					console.log('plaGeoData', this.plaGeoData);
					console.log('childGeoData', this.childGeoData);
					console.log('hvGeoData', this.hvGeoData);
					console.log('legend', this.legend);

					this.initGeo();
				} else {
					// for sub map

					let distData = [];
					let plaGeoData = [];
					let childGeoData = [];
					let hvGeoData = [];
					respData.data.forEach(report => {
						if (report.reportType == 'geoPLAData') {
							report.data.forEach(d => {
								d.name = d.name.toUpperCase();
								// d.ipname = d.name.toUpperCase();
								d.type = subType;
							});
							plaGeoData = report.data;
							this.legend = report.legend
						}
						if (report.reportType == 'geoChildData') {
							report.data.forEach(d => {
								d.name = d.name.toUpperCase();
								// d.ipname = d.name.toUpperCase();
								d.type = subType;
							});
							childGeoData = report.data;
							this.legend = report.legend
						}
						if (report.reportType == 'geoHVData') {
							report.data.forEach(d => {
								d.name = d.name.toUpperCase();
								// d.ipname = d.name.toUpperCase();
								d.type = subType;
							});
							hvGeoData = report.data;
							this.legend = report.legend
						}
					});

					console.log('sub plaGeoData', plaGeoData);
					console.log('sub childGeoData', childGeoData);
					console.log('sub hvGeoData', hvGeoData);
					console.log('legend', this.legend);

					if (this.geoData.type == 'pla') {
						distData = plaGeoData;
					}
					if (this.geoData.type == 'hv') {
						distData = hvGeoData;
					}
					if (this.geoData.type == 'child') {
						distData = childGeoData;
					}

					this.continueSubDataLoad(distData);					
				}
			});

		} else {
			console.warn('geo type missing!');
		}
	}

	setCenterValue(params, cord) {
		console.log(params.data.type, ' cord', cord.properties.dtname);
		let allCords = [];
		cord.geometry.coordinates.forEach(d => {
			// console.log('d d >>>', d.length);
			if (d.length == 1) {
				allCords = allCords.concat(...d);
			} else {
				allCords = allCords.concat(d);
			}
		});
		// console.log('allCords', allCords);
		const centerVal = this.utility.getCenterFromDegrees(allCords);
		console.log('centerVal', centerVal);
		this.geoOption.series[0].center = centerVal;
		this.geoOption.series[0].zoom = this.mapZoom;
		this.geoChart.setOption(this.geoOption);
		this.getSubMap(params, params.data.type,cord.properties.dtname);
	}

	setCenterValue2(cord) {
		console.log('setCenterValue called', cord);
		let allCords = [];
		cord.geometry.coordinates.forEach(d => {
			// console.log('d d >>>', d.length);
			if (d.length == 1) {
				allCords = allCords.concat(...d);
			} else {
				allCords = allCords.concat(d);
			}
		});
		// console.log('allCords', allCords);
		const centerVal = this.utility.getCenterFromDegrees(allCords);
		console.log('centerVal', centerVal);
		this.geoOption.series[0].center = centerVal;
		// this.geoOption.series[0].zoom = this.mapZoom;

		// this.geoChart.setOption(this.geoOption);
		// this.getSubMap(params, params.data.type);
	}

	getDataList(data, type, change) {
		const arr = [];
		for (let i = 0; i < data.length; i++) {
			const feature = data[i];
			const obj = { id: (type + '-' + (100 + i)), name: feature.properties.name, value: 0, type, change };
			arr.push(obj);
		}
		return arr;
	}

	getSubMap(params, type,value) {
		console.log('getSubMap called', params, type);
		this.geoChart.showLoading();
		let fileName = this.geoData.selected.dist.name; // need to check
		let name = params.data.name;
		fileName = fileName.toLowerCase().replace(' ', '');
		let distName = value.toLowerCase().replace(' ', '');
		name = name.replace(' ', '');
		console.log("geo data",value);
		let fileType = 'block';
		let selection = 'block';
		let subSelection = 'block';
		if (type == 'dist') {
			name = name.toLowerCase().replace(' ', '');
			fileType = 'block';
			selection = 'dist';
			subSelection = 'level2';
		} else if (type == 'level2') {
			fileType = 'cluster';
			selection = 'block';
			subSelection = 'level3';
		} else if (type == 'level3') {
			fileType = 'village';
			selection = 'cluster';
			subSelection = 'village';
		}

		const url = './assets/geo/' + distName + '-' + fileType + '.geojson';

		this.http.get(url).subscribe((geoJson: any) => {
			console.log(name + ' geoJson', geoJson);

			// d.properties.geoId = d.properties.dtcode;
			// d.properties.geoId = d.properties.ipcode;
			// d.properties.geoId = d.properties.cluster_co;
			// d.properties.geoId = d.properties.vlgcode;

			geoJson.features.forEach(d => {
				if (type == 'dist') {
					d.properties.name = d.properties.nhm_block;
					d.properties.geoId = d.properties.nhm_code;
				} else if (type == 'level2') {
					d.properties.name = d.properties.cluster_na;
					d.properties.geoId = d.properties.cluster_co;
					d.properties.change = true;
				} else if (type == 'level3') {
					d.properties.name = d.properties.villname;
					d.properties.geoId = d.properties.vlgcode;
					d.properties.change = true;
					d.properties.subChange = true;
				} else if (type == 'village') {
					d.properties.name = d.properties.villname;
					d.properties.geoId = d.properties.vlgcode;
					d.properties.change = true;
					d.properties.subChange = true;
				}
				d.properties.name = d.properties.name.toUpperCase();
			});

			let cordList = [];
			let removedData = {};

			// filter only clicked option
			if (type == 'level2') {
				cordList = geoJson.features.filter(b => b.properties.nhm_block == name);
				removedData = this.jhJSON.features.splice(this.selectedBlockIndex, 1, ...cordList)[0];
				this.jhJSON = this.jhJSON;
				// this.jhJSON = cordList;
			} else if (type == 'level3') {
				cordList = geoJson.features.filter(b => b.properties.cluster_na == name);
				removedData = this.jhJSON.features.splice(this.selectedClusterIndex, 1, ...cordList)[0];
				this.jhJSON = this.jhJSON;
			} else {
				cordList = geoJson.features;
				removedData = this.jhJSON.features.splice(this.selectedDistrictIndex, 1, ...cordList)[0];
				this.jhJSON = this.jhJSON;
			}

			const stackData = this.selectedStack.get(type);
			stackData.geoData = removedData;
			this.selectedStack.set(type, stackData);

			console.log('updated json', this.jhJSON);
			echarts.registerMap(name, this.jhJSON, {});
			this.geoOption.series[0].map = name;
			// this.geoOption.series[0].nameProperty = 'ipname';

			// dynamic values from API call
			// random call
			// if (params.data && params.data.id) {
			// 	let filterData = [{ 'resourceType': params.data.type, 'resourceId': +params.data.id }];
			// 	this.getGeoData(filterData);
			// } else {
			// 	console.warn('Invalid data!');
			// }

			let filterData = [{ 'resourceType': selection, 'resourceId': params.data.id }];
			this.getGeoData(filterData, false, subSelection);

			// for custom data
			// let distData = [];
			// if (type == 'dist') {
			// 	distData = this.getDataList(cordList, 'level2', false);
			// } else if (type == 'level2') {
			// 	distData = this.getDataList(cordList, 'level3', true);
			// } else if (type == 'level3') {
			// 	distData = this.getDataList(cordList, 'village', true);
			// }
			// console.log('distData', distData);

			// this.continueSubDataLoad(distData);
		});

		console.log('selectedStack', this.selectedStack);
	}

	continueSubDataLoad(distData) {
		this.geoChart.hideLoading();

		// to make selectable with blur
		this.selectedGeoData.forEach(d => {
			d.value = undefined;
		});
		this.selectedGeoData.push(...distData);
		this.rebuildGeoChart();
	}

	changeGeoData(type) {
		console.log('changeGeoData called', type);
		this.selectedGeoType = type;
		switch (type) {
			case 'child':
				this.selectedGeoData = this.childGeoData;
				this.selectedGeoTitle = 'Child Enrolled Coverage';
				this.selectedGeoLegend = 'No. of \n Child Enrolled Coverage';
				break;
			case 'hv':
				this.selectedGeoData = this.hvGeoData;
				this.selectedGeoTitle = 'Home Visit Coverage';
				this.selectedGeoLegend = 'No. of \n Home Visits';
				break;
			case 'pla':
				this.selectedGeoData = this.plaGeoData;
				this.selectedGeoTitle = 'PLA Coverage';
				this.selectedGeoLegend = 'No. of \n PLA Meetings';
				break;
			default:
				this.selectedGeoData = this.defaultGeoData;
				this.selectedGeoTitle = 'Jhankhand';
				this.selectedGeoLegend = 'Total Data';
				break;
		}

		this.rebuildGeoChart();
	}

	rebuildGeoChart() {
		console.log('rebuildGeoChart called');
		if (this.geoOption) {
			this.geoOption.title.text = this.selectedGeoTitle;
			this.geoOption.series[0].data = this.selectedGeoData;
			this.geoOption.visualMap.text[0] = this.selectedGeoLegend;
			this.geoOption.visualMap.pieces = this.legend;
			this.geoChart.setOption(this.geoOption);
			console.log('geoChart', this.geoChart);
		}
	}

	zoomIn() {
		console.log('zoomIn called', this.mapZoom);
		this.mapZoom = Math.floor(this.mapZoom);
		this.mapZoom++;
		if (this.mapZoom > this.maxZoom) {
			this.mapZoom = this.maxZoom;
		}

		this.geoOption.series[0].zoom = this.mapZoom;
		this.geoChart.setOption(this.geoOption);
	}
	zoomOut() {
		console.log('zoomOut called', this.mapZoom);
		this.mapZoom = Math.floor(this.mapZoom);
		this.mapZoom--;
		if (this.mapZoom < this.minZoom) {
			this.mapZoom = this.minZoom;
		}

		this.geoOption.series[0].zoom = this.mapZoom;
		this.geoChart.setOption(this.geoOption);
	}

	reset() {
		console.log('reset called', this.mapZoom);
		this.mapZoom = this.DEFAULT_ZOOM;
		this.geoChart.showLoading();
		let filterData = [];
		// let filterData = [{ 'resourceType': 'state', 'resourceId': 10 }];
		this.getGeoData(filterData, true);

		// let geoJson = JSON.parse(JSON.stringify(this.ogJHJSON));
		// echarts.registerMap('Jharkhand', geoJson, {});

		// this.geoOption.series[0].zoom = this.mapZoom;
		// this.geoOption.series[0].data = this.selectedGeoData;
		// this.geoChart.setOption(this.geoOption);
	}

	getJHCords() {
		return JHCords;
	}
}
