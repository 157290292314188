import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ERRORS } from 'app/configs/app.config';
import { UtilService } from 'app/shared/services/util.service';

@Component({
	selector: 'app-skip',
	templateUrl: './skip.component.html',
	styleUrls: ['./skip.component.scss']
})
export class SkipComponent implements OnInit {
	selectedReason: any = '';
	reasons: any = [
		{
			value: 'migrated',
			name: 'migrated'
		},
		{
			value: 'admitted',
			name: 'admitted'
		},
		{
			value: 'notfound',
			name: 'notfound'
		},
		{
			value: 'others',
			name: 'others'
		},
	];
	constructor(
		public dialogRef: MatDialogRef<SkipComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private utility: UtilService
	) { }

	done() {
		if (this.selectedReason) {
			this.dialogRef.close(this.selectedReason);
		} else {
			this.utility.showToast(ERRORS.SELECTREASON);
		}
	}

	cancel() {
		this.dialogRef.close();
	}

	ngOnInit(): void {
		console.log('data passed', this.data);
	}
}
