<div class="text-center mat-bg-primary pt-16 pb-16">
  <h4 class="m-0 text-white">{{'notifications' | translate}}
		<button mat-icon-button class="pull-right refresh-btn" (click)="refershNotificationCount()">
			<mat-icon>refresh</mat-icon>
		</button>
	</h4>
</div>
<!-- <mat-nav-list class="notification-list" role="list">
  <mat-list-item *ngFor="let n of notifications" class="notific-item" role="listitem" routerLinkActive="open">
    <mat-icon [color]="n.color" class="notific-icon mr-16">{{n.icon}}</mat-icon>
    <a>
      <div class="mat-list-text">
        <h4 class="message">{{n.message}}</h4>
        <small class="time text-muted">{{n.time}}</small>
      </div>
    </a>
  </mat-list-item>
</mat-nav-list> -->
<div style="padding: 4px; min-width: 240px;">
	<div class="not-header" style="background: #efefef;" *ngIf="showMore">
		<button mat-icon-button (click)="showMore = false;">
			<mat-icon>arrow_back</mat-icon>
		</button>
		<span>{{'notification.'+selectedProgram | translate}}</span>
	</div>
	<div *ngIf="!showMore">
		<mat-card *ngFor="let item of notifications | keyvalue" style="width: 240px;" matRipple (click)="showDetails(item.value, item.key)" class="cp notification-card" [hidden]="!notifications[item.key]?.totalCount">
			<mat-card-content>
				<h4 style="margin-bottom: 40px;">{{'notification.'+ item.key | translate}}
					<span class="badge"
						style="float: right; background: #ffdede; padding: 5px; border-radius: 12px; font-size: 12px; width: 14px; height: 14px; text-align: center; position: absolute; right: 10px; top: 10px;">{{notifications[item.key]?.totalCount}}</span>
				</h4>
				<div
					style="display: flex; justify-content: flex-end; color: #888889; font-size: 90%; position: absolute; right: 4px; bottom: 4px;" [hidden]="item.key == 'hvCount' || item.key == 'plaCount'">
					<span><small>{{'notification.expand' | translate}}</small></span>
					<span>
						<mat-icon style="font-size: 18px;">navigate_next</mat-icon>
					</span>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
	<div *ngIf="showMore">
		<mat-card *ngFor="let item of subNotifications | keyvalue" style="width: 240px;" matRipple (click)="navigate(item.key, item.value)" class="cp notification-card" [hidden]="!item.value">
			<mat-card-content>
				<h4 style="margin-bottom: 40px;">{{'notification.'+ item.key | translate}}
					<span class="badge"
						style="float: right; background: #ffdede; padding: 5px; border-radius: 12px; font-size: 12px; width: 14px; height: 14px; text-align: center; position: absolute; right: 10px; top: 10px;">{{item.value}}</span>
				</h4>
				<div
					style="display: flex; justify-content: flex-end; color: #888889; font-size: 90%; position: absolute; right: 4px; bottom: 4px;">
					<span><small>View Entries</small></span>
					<span>
						<mat-icon style="font-size: 18px;">navigate_next</mat-icon>
					</span>
				</div>
			</mat-card-content>
		</mat-card>
	</div>
	<div *ngIf="noData">
		<div class="text-center" style="margin: 20% 0;">
			{{'noNotification' | translate}}
		</div>
	</div>
</div>
<!-- <div class="text-center mt-16" *ngIf="notifications.length">
  <small><a href="#" (click)="clearAll($event)">Clear all notifications</a></small>
</div> -->