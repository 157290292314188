import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtAuthService } from './auth/jwt-auth.service';
import { LocalStoreService } from './local-store.service';
import { ApiConfig } from 'app/configs/api.config';
import { DataService } from './data.service';
import { environment } from 'environments/environment';

// import { UserService } from './user.service';
// import { Storage } from '@ionic/storage';
// import { ApiConfig } from '@km/configs';
// import { MiscService } from './misc.service';

@Injectable({
	providedIn: 'root'
})
export class HttpService {

	userOnline = true;
	
	constructor(
		private ls: LocalStoreService, private http: HttpClient, private jwt: JwtAuthService, private dataService: DataService) {

		this.userOnline = this.jwt.isUserOnline();
	}
	
	isUserOnline() {
		this.userOnline = this.jwt.isUserOnline();
		return this.userOnline;
	}

	createAuthorizationHeader(params?: any) {
		let token = this.ls.getItem('JWT_TOKEN');
		if (token) {
			token = "Bearer " + token;
		}
		if (params && params.authorization) {
			token = params.authorization;
		}
		if (!token || token === '' || token === undefined) {
			token = '';
		}

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Authorization', token);
		headers = headers.append('X-Action', (params && params.action) ? params.action : '');
		return headers;
	}

	getPostUrl() {
		return ApiConfig.apiUrl;
	}

	get(url: string, params?: any) {
		// console.log('http call with custom headers');
		// const data: any = {};
		const headers: any = this.createAuthorizationHeader(params);
		return this.http.get(url, {
			headers
		});
	}

	post(url: string, data: any, params?: any) {
		// console.log('http call with custom headers');
		const headers: any = this.createAuthorizationHeader(params);

		if(this.isUserOnline() && environment.production){
			setTimeout(()=>{
				this.dataService.sentData({
					type: 'refresh'
				});
			},3000);
		}
		return this.http.post(url, data, {
			headers
		});
	}

	put(url: string, data: any, params?: any) {
		// console.log('http call with custom headers');
		const headers: any = this.createAuthorizationHeader(params);
		return this.http.put(url, data, {
			headers
		});
	}

	delete(url: string, data: any, params?: any) {
		// console.log('http call with custom headers');
		const headers: any = this.createAuthorizationHeader(params);
		return this.http.delete(url, {
			headers
		});
	}
}
