import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {

	private dataSource = new BehaviorSubject('default data');
	onDataReceived = this.dataSource.asObservable();
	public localData: any = {};

	constructor() { }

	getData(key){
		return this.localData[key];
	}

	setData(key,value){
		if (key != undefined && value != undefined){
			this.localData[key] = value;
		}
	}
	
	sentData(data: any) {
		this.dataSource.next(data);
	}
}
