<table class="weight-section">
	<tbody>
		<tr>
			<td class="form-group mb-3"><input type="text" maxlength="2" class="form-control text-center bs-timepicker-field"
					[placeholder]="'registerChild.form.weight.kg' | translate" aria-label="hours"></td>
			<td>&nbsp;:&nbsp;</td>
			<td class="form-group mb-3"><input type="text" maxlength="3" class="form-control text-center bs-timepicker-field"
					[placeholder]="'registerChild.form.weight.gram' | translate" aria-label="minutes"></td>
		</tr>
	</tbody>
</table>
