import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'weight-selector',
  templateUrl: './weight.component.html',
  styleUrls: ['./weight.component.scss']
})
export class WeightComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
