// let hostName = 'http://15.207.173.27';
let hostName = 'http://localhost';
let port = 3000;

export const environment = {
	production: false,
	hostName,
	hostURL: hostName + ':' + port,
	apiURL: hostName + ':' + port + '/api',
};


