import { Injectable } from '@angular/core';
import { LocalStoreService } from '../local-store.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { map, catchError, delay } from 'rxjs/operators';
import { User } from '../../models/user.model';
import { of, BehaviorSubject, throwError } from 'rxjs';
import { environment } from 'environments/environment';
import { UtilService } from '../util.service';
import { ERRORS } from 'app/configs/app.config';
import { ApiConfig } from 'app/configs/api.config';
// import { HttpService } from '../http.service';
import { AppLoaderService } from '../app-loader/app-loader.service';
import { DataService } from '../data.service';

// ================= only for demo purpose ===========
const DEMO_TOKEN =
	'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI1YjhkNDc4MDc4NmM3MjE3MjBkYzU1NzMiLCJlbWFpbCI6InJhZmkuYm9ncmFAZ21haWwuY29tIiwicm9sZSI6IlNBIiwiYWN0aXZlIjp0cnVlLCJpYXQiOjE1ODc3MTc2NTgsImV4cCI6MTU4ODMyMjQ1OH0.dXw0ySun5ex98dOzTEk0lkmXJvxg3Qgz4ed';

// const DEMO_USER: User = {
// 	id: '5b700c45639d2c0c54b354ba',
// 	displayName: 'Shanta Devi',
// 	role: 'SA',
// };

const DEMO_USER = {
	'resourceId': 1234,
	'resourceName': null,
	'resourceType': 'profile',
	'rTag': null,
	'attributes': {
		'profileId': '34715301',
		'userName': 'Shanta Devi',
		'location': 'Rachi, Jharkhand',
		'phone': 1231231231,
		'profilePhotoURL': './assets/imgs/woman.png'
	}
}
// ================= you will get those data from server =======

@Injectable({
	providedIn: 'root',
})
export class JwtAuthService {
	token;
	userDetails;
	isAuthenticated: boolean;
	user: User = {
		resourceId: 0,
		resourceName: '',
		resourceType: '',
		rTag: undefined,
		attributes: {
			profileId: undefined,
			userName: '',
			location: '',
			phone: undefined,
			profilePhotoURL: ''
		}
	};
	user$ = (new BehaviorSubject<User>(this.user));
	signingIn: boolean;
	return: string;
	JWT_TOKEN = 'JWT_TOKEN';
	APP_USER = 'SIMA_USER';
	notificationCount = new BehaviorSubject({});
	userOnline: any = true;
	mockOffline: any = false;
	plaEntries: any = [];
	hvEntries: any = [];
	childEntries: any = [];

	onlineSource = new BehaviorSubject(false);
	onStatusChange = this.onlineSource.asObservable();

	districtList: any = [];
	blockList: any = [];
	clusterList: any = [];

	selectedDistrict: any = {};
	selectedBlock: any = {};
	selectedCluster: any = {};
	constructor(
		private ls: LocalStoreService,
		private http: HttpClient,
		private router: Router,
		private loader: AppLoaderService,
		private route: ActivatedRoute,
		private util: UtilService,
		private dataService: DataService
	) {
		this.route.queryParams
			.subscribe(params => this.return = params['return'] || '/home');

		this.userOnline = navigator.onLine;
		window.addEventListener('online', this.checkOnline.bind(this));
		window.addEventListener('offline', this.checkOnline.bind(this));

		this.plaEntries = this.ls.getItem('plaEntries') ?? [];
		console.log('pending pla local entries', this.plaEntries);

		this.hvEntries = this.ls.getItem('hvEntries') ?? [];
		console.log('pending hv local entries', this.hvEntries);

		this.childEntries = this.ls.getItem('childEntries') ?? [];
		console.log('pending child local entries', this.childEntries);

		this.mockOffline = this.ls.getItem('isOffline') ?? false;
		console.log('user is offline', this.mockOffline);
	}
	ngOnInit(){
		
		this.checkOnlineData();
	}
	setDropDowns() {
		this.districtList = this.ls.getItem('districtList');
		this.blockList = this.ls.getItem('blockList');
		this.clusterList = this.ls.getItem('clusterList');
		const selectedDistrict = this.ls.getItem('selectedDistrict') || {};
		const selectedBlock = this.ls.getItem('selectedBlock') || {};
		const selectedCluster = this.ls.getItem('selectedCluster') || {};
		this.selectedDistrict = this.districtList.filter(d => d.id === selectedDistrict.id)[0] || {};
		this.selectedBlock = this.blockList.filter(d => d.id === selectedBlock.id)[0] || {};
		this.selectedCluster = this.clusterList.filter(d => d.id === selectedCluster.id)[0] || {};

		console.log('districtList', this.districtList);
		console.log('blockList', this.blockList);
		console.log('clusterList', this.clusterList);
		console.log('selectedDistrict', this.selectedDistrict);
		console.log('selectedBlock', this.selectedBlock);
		console.log('selectedCluster', this.selectedCluster);

	}

	checkOnlineData(){
		// console.log(navigator.onLine)
		if(navigator.onLine){
			this.ls.setItem('isOffline',!navigator.onLine);
			this.mockOffline = false;
		}
	}
	getStatus(proir?) {
		if (proir) {
			return navigator.onLine;
		} else {
			if (this.mockOffline) {
				return false;
			} else {
				return navigator.onLine;
			}
		}
	}

	setMock(status, hideToast?: any) {
		console.log('setMock called', status);
		this.mockOffline = status ?? false;
		this.userOnline = this.getStatus();

		if (this.userOnline) {
			if (hideToast == undefined) {
				this.util.showToast(ERRORS.ONLINE, 'toast-success');
				this.syncData();
			}
		} else {
			this.mockOffline = true;
			this.util.showToast(ERRORS.OFFLINE);
		}
		// publish event
		this.publishEvent({
			type: 'active',
			status: this.userOnline
		});

		if (this.userDetails.attributes.roleId && this.userDetails.attributes.roleId < 5) {
			this.router.navigateByUrl('/home');
		}
	}

	isUserOnline() {
		this.userOnline = this.getStatus();
		return this.userOnline;
	}

	checkOnline(event) {
		this.userOnline = this.getStatus(true);
		console.warn('user is online', this.userOnline, event.type);

		if (this.userOnline) {
			this.util.showToast(ERRORS.ONLINE, 'toast-success');
			this.mockOffline = false;
			this.syncData();
		} else {
			this.mockOffline = true;
			this.util.showToast(ERRORS.OFFLINE);
		}
		// publish event
		this.publishEvent({
			type: 'active',
			status: this.userOnline
		});

		if (this.userDetails.attributes.roleId && this.userDetails.attributes.roleId < 5) {
			this.router.navigateByUrl('/home');
		}
	}

	getRoleAccess(inc?, geo?) {
		this.setDropDowns();
		if (this.userDetails && this.userDetails.attributes) {
			// role = 1 // admin
			// role = 2 // state
			// role = 3 // dist
			// role = 4 // block
			// role = 5 // cluster
			console.log(inc,geo);
			switch (this.userDetails.attributes.roleId) {
				case 1:
				case '1':
					if (inc) {
						if (geo) {
							return 'dist';
						}
						return 'state';
					}
					return 'admin';
				case 2:
				case '2':
					if (inc) {
						if (geo) {
							return 'dist';
						}
						return 'dist';
					}
					return 'state';
				case 3:
				case '3':
					if (inc) {
						if (geo) {
							return 'level2';
						}
						return 'block';
					}
					return 'dist';
				case 4:
				case '4':
					if (inc) {
						if (geo) {
							return 'level3';
						}
						return 'cluster';
					}
					return 'block';
				case 5:
				case '5':
					if (inc) {
						if (geo) {
							return 'village';
						}
						return 'village';
					}
					return 'cluster';
				case 7:
					case '7':
						if(this.districtList.length > 1 && this.blockList.length > 1 && this.clusterList.length > 1) {
							if (inc) {
								if (geo) {
									return 'dist';
								}
								return 'state';
							}
							return 'admin';
						}
						else if(this.districtList.length == 1 && this.blockList.length > 1 && this.clusterList.length > 1) {
							if (inc) {
								if (geo) {
									return 'block';
								}
								return 'block';
							}
							return 'dist';
						}
						else if(this.districtList.length == 1 && this.blockList.length == 1 && this.clusterList.length > 1) {
							if (inc) {
								if (geo) {
									return 'cluster';
								}
								return 'cluster';
							}
							return 'block';
						}
						else if(this.districtList.length == 1 && this.blockList.length == 1 && this.clusterList.length == 1) {
							if (inc) {
								if (geo) {
									return 'village';
								}
								return 'village';
							}
							return 'cluster';
						}
						
				case 8:
					case '8':
						if(this.districtList.length == 1 && this.blockList.length > 1 && this.clusterList.length > 1) {
							if (inc) {
								if (geo) {
									return 'block';
								}
								return 'block';
							}
							return 'dist';
						}
						else if(this.districtList.length == 1 && this.blockList.length == 1 && this.clusterList.length > 1) {
							if (inc) {
								if (geo) {
									return 'cluster';
								}
								return 'cluster';
							}
							return 'block';
						}
						else if(this.districtList.length == 1 && this.blockList.length == 1 && this.clusterList.length == 1) {
							if (inc) {
								if (geo) {
									return 'village';
								}
								return 'village';
							}
							return 'cluster';
						}
			}

			return 0;
		} else {
			console.warn('Invalid data!');
		}
	}

	publishEvent(data: any) {
		this.onlineSource.next(data);
	}

	async syncData() {
		console.log('syncing data...');

		this.plaEntries = this.ls.getItem('plaEntries') ?? [];
		console.log('pending pla local entries', this.plaEntries);

		this.hvEntries = this.ls.getItem('hvEntries') ?? [];
		console.log('pending hv local entries', this.hvEntries);

		this.childEntries = this.ls.getItem('childEntries') ?? [];
		console.log('pending child local entries', this.childEntries);

		let submitList = [];
		if (this.plaEntries && this.plaEntries.length) {
			this.plaEntries.forEach(entry => {
				entry.type = 'pla';
			});
			submitList.push(...this.plaEntries);
		}

		if (this.hvEntries && this.hvEntries.length) {
			this.hvEntries.forEach(entry => {
				entry.type = 'hv';
			});
			submitList.push(...this.hvEntries);
		}

		if (this.childEntries && this.childEntries.length) {
			this.childEntries.forEach(entry => {
				entry.type = 'child';
			});
			submitList.push(...this.childEntries);
		}

		console.log('submitList', submitList);

		if (submitList && submitList.length) {
			this.loader.open('Syncing data...');
			let respList = [];
			let errorList = [];

			for (let i = 0; i < submitList.length; i++) {
				const entry = submitList[i];
				try {
					let res = await this.submitForm(entry);
					respList.push(res);
				} catch (err) {
					errorList.push(err);
				}
			}

			if (respList && respList.length === submitList.length) {
				console.log('all synced', respList);
				this.util.showToast('All data synced successfully!', 'toast-success');
			} else {
				console.log('few syncs', respList);
				console.log('few err', errorList);
				this.util.showToast('Error while syncing!', 'toast-error');
			}

			this.loader.close();
			this.updateSyncData(submitList, respList, errorList);
		} else {
			console.log('no data to sync');
		}
	}

	submitForm(sendData) {

		if (sendData.type == 'pla') {
			return this.post(ApiConfig.pla.submitForm, [sendData.formData], { action: 'submitFormData' }).toPromise().then((respData: any) => {
				console.log('respData', respData);
				return {
					type: sendData.type,
					id: sendData.id,
					respData
				};
			}, (error) => {
				return {
					type: sendData.type,
					id: sendData.id,
					error
				};
			});
		}

		if (sendData.type == 'child') {
			return this.post(ApiConfig.newChild.submitForm, [sendData.formData], { action: 'submitFormData' }).toPromise().then((respData: any) => {
				console.log('respData', respData);
				return {
					type: sendData.type,
					id: sendData.id,
					respData
				};
			}, (error) => {
				return {
					type: sendData.type,
					id: sendData.id,
					error
				};
			});
		}

		if (sendData.type == 'hv') {
			return this.post(ApiConfig.homeVisit.submitForm, [sendData.formData], { action: 'submitFormData' }).toPromise().then((respData: any) => {
				console.log('respData', respData);
				return {
					type: sendData.type,
					id: sendData.id,
					respData
				};
			}, (error) => {
				return {
					type: sendData.type,
					id: sendData.id,
					error
				};
			});
		}
	}

	updateSyncData(submitList, respList, errorList) {
		console.log('updateSyncData called', submitList, respList, errorList);

		respList.forEach(rd => {
			if (rd.type == 'pla') {
				this.plaEntries = this.ls.getItem('plaEntries');
				this.plaEntries = this.plaEntries.filter(p => rd.id !== p.id);
				this.ls.setItem('plaEntries', this.plaEntries);
			}
			if (rd.type == 'hv') {
				this.hvEntries = this.ls.getItem('hvEntries') ?? [];
				this.hvEntries = this.hvEntries.filter(p => rd.id !== p.id);
				this.ls.setItem('hvEntries', this.hvEntries);
			}
			if (rd.type == 'child') {
				this.childEntries = this.ls.getItem('childEntries') ?? [];
				this.childEntries = this.childEntries.filter(p => rd.id !== p.id);
				this.ls.setItem('childEntries', this.childEntries);
			}
		});
		console.log('local data updated!');

		// publish event
		this.dataService.sentData({ type: 'offline-refresh' });
	}

	public signin(token, userData) {
		return of({ token, user: userData })
			.pipe(
				delay(1000),
				map((res: any) => {
					this.setUserAndToken(res.token, res.user, !!res);
					this.signingIn = false;
					return res;
				}),
				catchError((error) => {
					return throwError(error);
				})
			);

		// FOLLOWING CODE SENDS SIGNIN REQUEST TO SERVER

		// this.signingIn = true;
		// return this.http.post(`${environment.apiURL}/auth/local`, { username, password })
		//   .pipe(
		//     map((res: any) => {
		//       this.setUserAndToken(res.token, res.user, !!res);
		//       this.signingIn = false;
		//       return res;
		//     }),
		//     catchError((error) => {
		//       return throwError(error);
		//     })
		//   );
	}

	/*
		checkTokenIsValid is called inside constructor of
		shared/components/layouts/admin-layout/admin-layout.component.ts
	*/
	public checkTokenIsValid() {
		return of(this.ls.getItem(this.APP_USER))
			.pipe(
				map((profile: User) => {
					this.setUserAndToken(this.getJwtToken(), profile, true);
					this.signingIn = false;
					return profile;
				}),
				catchError((error) => {
					return of(error);
				})
			);

		/*
			The following code get user data and jwt token is assigned to
			Request header using token.interceptor
			This checks if the existing token is valid when app is reloaded
		*/

		// return this.http.get(`${environment.apiURL}/api/users/profile`)
		//   .pipe(
		//     map((profile: User) => {
		//       this.setUserAndToken(this.getJwtToken(), profile, true);
		//       return profile;
		//     }),
		//     catchError((error) => {
		//       this.signout();
		//       return of(error);
		//     })
		//   );
	}

	public signout() {
		this.ls.clear();
		this.setUserAndToken(null, null, false);
		this.router.navigateByUrl('sessions/signin');
	}

	isLoggedIn(): boolean {
		return !!this.getJwtToken();
	}

	getJwtToken() {
		return this.ls.getItem(this.JWT_TOKEN);
	}
	getUser() {
		return this.ls.getItem(this.APP_USER) || { attributes: {} };
	}

	setUserAndToken(token: string, user?: User, isAuthenticated?: boolean) {
		console.log('setUserAndToken called', user);
		this.isAuthenticated = isAuthenticated;
		this.token = token;
		this.userDetails = user;
		this.ls.setItem(this.JWT_TOKEN, token);

		this.user = user;
		this.user$.next(user);
		this.ls.setItem(this.APP_USER, user);
	}

	encode(token) {
		return atob(token);
	}
	decode(token) {
		return btoa(token);
	}

	clear() {
		this.setUserAndToken(null, null, false);
	}

	getNotification() {
		return this.notificationCount;
	}

	setNotification(count) {
		console.log('setNotification called', count);
		this.notificationCount.next(count);
	}


	createAuthorizationHeader(params?: any) {
		let token = this.ls.getItem('JWT_TOKEN');
		if (token) {
			token = 'Bearer ' + token;
		}
		if (params && params.authorization) {
			token = params.authorization;
		}
		if (!token || token === '' || token === undefined) {
			token = '';
		}

		let headers: HttpHeaders = new HttpHeaders();
		headers = headers.append('Authorization', token);
		headers = headers.append('X-Action', (params && params.action) ? params.action : '');
		return headers;
	}

	get(url: string, params?: any) {
		// console.log('http call with custom headers');
		// const data: any = {};
		const headers: any = this.createAuthorizationHeader(params);
		return this.http.get(url, {
			headers
		});
	}

	post(url: string, data: any, params?: any) {
		// console.log('http call with custom headers');
		const headers: any = this.createAuthorizationHeader(params);

		if (this.isUserOnline()) {
			setTimeout(() => {
				this.dataService.sentData({
					type: 'refresh'
				});
			}, 3000);
		}

		return this.http.post(url, data, {
			headers
		});
	}
}
