<div class="geo-chart-section">
	<div class="zoom-container" [ngClass.sm]="{'mobile': true}">
		<div class="zoom-icon zoom-in" (click)="reset()" matTooltip="Reset Zoom">
			<span class="material-icons">
				replay
			</span>
		</div>
		<div class="zoom-icon zoom-in" (click)="zoomIn()" matTooltip="Zoom In">
			<span class="material-icons">
				zoom_in
			</span>
		</div>
		<div class="zoom-icon zoom-out" (click)="zoomOut()" matTooltip="Zoom Out">
			<span class="material-icons">
				zoom_out
			</span>
		</div>
	</div>
	<!-- <img src="images/heatmap.png" alt="heatmap" style="width: 100%;"> -->
	<div id="geoChart" class="jh-charts" echarts [options]="chartOption" style="height: 500px;" (chartInit)="onChartInit($event)"></div>
	<!-- <div id="geoChart" class="jh-charts" style="width: 100%;height:500px;"></div> -->
</div>