<div class="text-center">
	<h1 mat-dialog-title>{{'skipModal.title' | translate}}</h1>
	<div mat-dialog-content>
		<!-- <p>{{'skipModal.message' | translate}}</p> -->
		<mat-form-field appearance="standard" class="full-width">
			<mat-label>{{'skipModal.input.label' | translate}}</mat-label>
			<mat-select [(ngModel)]="selectedReason">
				<mat-option *ngFor="let reason of reasons" [value]="reason.value">
					{{'skipModal.input.'+reason.name | translate}}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</div>
	<div mat-dialog-actions class="action-btns">
		<button mat-raised-button color="primary" (click)="done()">{{'submit' | translate}}</button>
		<button mat-raised-button (click)="cancel()">{{'cancel' | translate}}</button>
	</div>
</div>