import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataService } from './data.service';

@Injectable({
	providedIn: 'root'
})
export class UtilService {

	constructor(private _snackBar: MatSnackBar, private dataService: DataService) { }

	numPad(str, n) {
		for (var r = str.toString(); r.length < n; r = 0 + r) { }
		return r;
	}

	getDateDifference(d1, d2) {
		const date1: any = new Date(d1);
		const date2: any = new Date(d2);
		const diffTime = Math.abs(date2 - date1);
		const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
		console.log(diffTime + " milliseconds");
		console.log(diffDays + " days");
		return diffDays;
	}

	getValidDate(d = new Date(), format?: any) {
		if (d) {
			const fd = new Date(d);

			const date = fd.getDate();
			const month = fd.getMonth() + 1;
			const year = fd.getFullYear();
			let fDate = year + '-' + this.numPad(month, 2) + '-' + this.numPad(date, 2);
			if (format) {
				fDate = this.numPad(date, 2) + '-' + this.numPad(month, 2) + '-' + year;
			}
			return fDate;
		}
		return '';
	}

	isMobile() {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
			// true for mobile device
			return true;
		} else {
			// false for not mobile device
			return false;
		}
	}

	sortByKey(arr, key) {
		const list = arr || [];
		list.sort((a, b) => {
			const nameA = a[key].toUpperCase(); // ignore upper and lowercase
			const nameB = b[key].toUpperCase(); // ignore upper and lowercase
			if (nameA < nameB) {
				return -1;
			}
			if (nameA > nameB) {
				return 1;
			}

			// names must be equal
			return 0;
		});
		return list;
	}

	evaluate(value, operator, checkWith) {
		let returnValue;
		switch (operator) {
			/* Assignment operator */
			// case '=':
			// 	returnValue = value = checkWith;
			// 	break;
			// // Addition assignment
			// case '+=':
			// 	returnValue = value += checkWith;
			// 	break;
			// // Subtraction Assignment
			// case '-=':
			// 	returnValue = value -= checkWith;
			// 	break;
			// // Multiplication Assignment
			// case '*=':
			// 	returnValue = value *= checkWith;
			// 	break;
			// // Division Assignment
			// case '/=':
			// 	returnValue = value /= checkWith;
			// 	break;
			// // Remainder Assignment
			// case '%=':
			// 	returnValue = value %= checkWith;
			// 	break;
			// // Exponentiation Assignment
			// case '**=':
			// 	returnValue = value **= checkWith;
			// 	break;

			/* Arithmetic Operators */
			// Addition
			case '+':
				returnValue = value + checkWith;
				break;
			// Subtraction
			case '-':
				returnValue = value - checkWith;
				break;
			//  Multiplication
			case '*':
				returnValue = value * checkWith;
				break;
			//  Division
			case '/':
				returnValue = value / checkWith;
				break;
			//  Remainder
			case '%':
				returnValue = value % checkWith;
				break;
			//  Increment (Post increments by 1)
			case '$++':
				returnValue = value++;
				break;
			//  Increment (Pre increments by 1)
			case '++$':
				returnValue = ++value;
				break;
			// Decrement (Post decrements by 1)
			case '$--':
				returnValue = value--;
				break;
			// Decrement (Pre decrements by 1)
			case '--$':
				returnValue = --value;
				break;
			// Exponentiation (Power)
			case '**':
				returnValue = value ** checkWith;
				break;

			/* Comparison Operators */
			// Equal to
			case '==':
				returnValue = value == checkWith;
				break;
			// Not equal
			case '!=':
				returnValue = value != checkWith;
				break;
			// Strict equal to
			case '===':
				returnValue = value === checkWith;
				break;
			// Strict not equal to
			case '!==':
				returnValue = value !== checkWith;
				break;
			// Greater than
			case '>':
				returnValue = value > checkWith;
				break;
			// Greater than or equal to
			case '>=':
				returnValue = value >= checkWith;
				break;
			// Less than
			case '<':
				returnValue = value < checkWith;
				break;
			// Less than or equal to
			case '<=':
				returnValue = value <= checkWith;
				break;

			/* Logical Operators */
			// Logical AND
			case '&&':
				returnValue = value && checkWith;
				break;
			// Logical OR
			case '||':
				returnValue = value || checkWith;
				break;
			// Logical NOT
			case '!':
				returnValue = !value;
				break;

			/* Other Operators */
			// Ternary
			case '?:':
				// returnValue = value ? true: false;
				break;
			// Check In
			case '>>':
				returnValue = checkWith.includes(value) ? true : false;
				break;
			// Check Multiple In
			case '>>>':
				// returnValue = che	ckWith.includes(value) ? true : false;
				break;

			// default return value
			default:
				returnValue = value;
				break;
		}

		return returnValue;
	}

	getSymbol(type) {
		switch (type) {
			case 'count':
			case 'number':
				return '';
			case 'percent':
				return '%'
			default:
				return '';
		}
	}

	calculateWeight(val, type?) {
		let g = 0;
		if (type) {
			if (val.changedKg) {
				g = val.changedKg * 1000;
			}
			if (val.changedGram) {
				g = g + (+val.changedGram);
			}
			return g;
		} else {
			if (val.kg) {
				g = val.kg * 1000;
			}
			if (val.gram) {
				g = g + (+val.gram);
			}
			return g;
		}

		// if (type == 'kg') {
		// 	return 2100;
		// }
		// if (type == 'gram') {
		// 	return 2100;
		// }
	}

	splitWeight(val) {
		if (val) {
			let v = ((val / 1000) + '').split('.');
			return {
				kg: v[0],
				gram: (parseInt(v[1])*100).toString()
			};
		}

		return {
			kg: 0,
			gram: 0
		};
	}

	getOptionArray(min, max, options: any = {}) {
		let arr = [];
		for (let i = min; i <= max; i++) {
			arr.push({
				key: i,
				value: (options.value || i),
				dynamic: options.dynamic || false
			});
		}
		return arr;
	}

	showToast(message, type?, extraData?, action?, time?) {
		let msg = this.dataService.localData.translations[message];
		if (msg) {
			msg = (extraData) ? (msg + extraData) : msg;
		}
		this._snackBar.open(msg || message, action || 'OK', {
			panelClass: type || 'toast-info',
			duration: time || 3000
		});
	}

	hideToast() {
		this._snackBar.dismiss();
	}

	getRandomNumber() {
		return Math.floor(Math.random() * (10000000 - 100000 + 1)) + 100000;
	}

	getCenterFromDegrees(data) {
		if (!(data.length > 0)) {
			return false;
		}

		let num_coords = data.length;

		let X = 0.0;
		let Y = 0.0;
		let Z = 0.0;

		for (let i = 0; i < data.length; i++) {
			let lat = data[i][0] * Math.PI / 180;
			let lon = data[i][1] * Math.PI / 180;

			let a = Math.cos(lat) * Math.cos(lon);
			let b = Math.cos(lat) * Math.sin(lon);
			let c = Math.sin(lat);

			X += a;
			Y += b;
			Z += c;
		}

		X /= num_coords;
		Y /= num_coords;
		Z /= num_coords;

		let lon = Math.atan2(Y, X);
		let hyp = Math.sqrt(X * X + Y * Y);
		let lat = Math.atan2(Z, hyp);

		let newX = (lat * 180 / Math.PI);
		let newY = (lon * 180 / Math.PI);

		return new Array(newX, newY);
	}
}
