import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';

export const rootRouterConfig: Routes = [
	{
		path: '',
		redirectTo: 'sessions/signin',
		pathMatch: 'full'
	},
	{
		path: '',
		component: AuthLayoutComponent,
		children: [
			{
				path: 'sessions',
				loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
				data: { title: 'Session' }
			}
		]
	},
	{
		path: '',
		component: AdminLayoutComponent,
		canActivate: [AuthGuard],
		children: [
			{
				path: 'home',
				loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
				// data: { title: 'Home', breadcrumb: 'HOME' }
			},
			{
				path: 'view-report',
				loadChildren: () => import('./view-report/view-report.module').then(m => m.ViewReportModule),
				data: { title: 'View Report', breadcrumb: 'View Report' }
			},
			{
				path: 'mis-data-entry',
				loadChildren: () => import('./mis-data-entry/mis-data-entry.module').then(m => m.MisDataEntryModule),
				data: { title: 'MIS Reporting', breadcrumb: 'MIS Reporting' }
			},
			{
				path: 'view-dashboard',
				loadChildren: () => import('./view-dashboard/view-dashboard.module').then(m => m.ViewDashboardModule),
				data: { title: 'View Dashboard', breadcrumb: 'View Dashboard' }
			},
			{
				path: 'register-child',
				loadChildren: () => import('./register-new-child/register-new-child.module').then(m => m.RegisterNewChildModule),
				data: { title: 'Register New Child', breadcrumb: 'Register New Child' }
			},
			{
				path: 'existing-child-details',
				loadChildren: () => import('./existing-child-details/existing-child-details.module').then(m => m.ExistingChildDetailsModule),
				data: { title: 'Home Visit Data Entry', breadcrumb: 'Home Visit Data Entry' }
			},
			{
				path: 'others',
				loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
				data: { title: 'Others', breadcrumb: 'OTHERS' }
			},
			{
				path: 'search',
				loadChildren: () => import('./views/search-view/search-view.module').then(m => m.SearchViewModule)
			},
			// {
			// 	path: 'echarts',
			// 	loadChildren: () => import('./views/chart-example-view/chart-example-view.module').then(m => m.ChartExampleViewModule)
			// },
			// {
			// 	path: 'material',
			// 	loadChildren: () => import('./views/material-example-view/material-example-view.module').then(m => m.MaterialExampleViewModule)
			// },
		]
	},
	{
		path: '**',
		redirectTo: 'sessions/404'
	}
];

