import { Component, OnInit, EventEmitter, Input, ViewChildren, Output, Renderer2, OnDestroy } from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LayoutService } from '../../services/layout.service';
import { TranslateService } from '@ngx-translate/core';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { EgretNotifications2Component } from '../egret-notifications2/egret-notifications2.component';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { MatDrawer } from '@angular/material/sidenav';
import { DataService } from 'app/shared/services/data.service';
import { environment } from 'environments/environment';
import {ProgressSpinnerMode} from '@angular/material/progress-spinner';
import { ThemePalette } from '@angular/material/core';
import { interval } from 'rxjs';
import { ApiConfig } from 'app/configs/api.config';
@Component({
	selector: 'app-header-side',
	templateUrl: './header-side.template.html'
})
export class HeaderSideComponent implements OnInit, OnDestroy {
	@Input() notificPanel: MatDrawer;
	@ViewChildren(EgretNotifications2Component) noti;
	public availableLangs = [{
		name: 'English',
		code: 'en',
		selected: false,
		flag: 'flag-icon-us'
	}, {
		name: 'Hindi',
		code: 'hi',
		selected: false,
		flag: 'flag-icon-in'
	}];
	currentLang: any = '';
	notificationCount = 0;
	subs: any = [];
	isOffline: any = false;
	isProd = environment.production;

	public egretThemes;
	public layoutConf: any;
	public userDetails: any = {};
	notify : any = true;
	color: ThemePalette = 'primary';
	mode: ProgressSpinnerMode = 'indeterminate';
	value = 10;
	checkApproval: boolean;
	image : any;
	constructor(
		private ls: LocalStoreService,
		private themeService: ThemeService,
		private layout: LayoutService,
		public translate: TranslateService,
		private renderer: Renderer2,
		public jwtAuth: JwtAuthService,
		public dataService: DataService
	) { }
	ngOnInit() {
		let lang = this.ls.getItem('currentLang');
		if (lang) {
			this.currentLang = lang;
		} else {
			let lang = this.availableLangs[1];
			this.currentLang = lang;
			this.ls.setItem('currentLang', lang);
		}
		this.egretThemes = this.themeService.egretThemes;
		this.layoutConf = this.layout.layoutConf;
		this.translate.use(this.currentLang.code);
		this.layout.publishLayoutChange({
			sidebarStyle: 'closed'
		});
		this.userDetails = this.jwtAuth.getUser();
		console.log('jwtAuth user', this.userDetails);

		this.isOffline = this.ls.getItem('isOffline') ?? false;

		setTimeout(() => {
			let index = this.availableLangs.findIndex((v, i) => {
				if (v.code == this.currentLang.code) {
					return true;
				}
			});
			this.setLang(this.currentLang, index);
		}, 2000);

		let notiSub = this.jwtAuth.getNotification().subscribe((data: any) => {
			console.log('data changed', data);
			this.notificationCount = (data || 0);
		});

		let aSub = this.jwtAuth.onStatusChange.subscribe((data: any) => {
			console.log('active status changed', data);
			if (data) {
				this.isOffline = (data.status == false) ? true : false;
				this.ls.setItem('isOffline', this.isOffline);
			}
		});

		this.subs.push(aSub);
		this.subs.push(notiSub);
		
		this.checkOnline();
		if(this.userDetails.attributes.roleId == 7 || this.userDetails.attributes.roleId == 8){
			this.notify = false;
		}
		interval(1000).subscribe(x =>{
			let batch = this.ls.getItem("batch")
			if(batch == "INCOMPLETE")
				this.checkApproval = true;
			else
				this.checkApproval = false;
		})
		if(this.userDetails.attributes.profilePhotoURL !=='NULL' && this.userDetails.attributes.profilePhotoURL)
			this.image = ApiConfig.hostUrl+"/block-images/"+this.userDetails.attributes.profilePhotoURL
	}
	checkOnline(){
		// setInterval(() => {
			this.jwtAuth.checkOnlineData()
		// },5000)
	}

	

	ngOnDestroy() {
		if (this.subs && this.subs.length) {
			this.subs.forEach(sub => {
				sub.unsubscribe();
			});
		}
		this.subs = [];
	}

	setLang(lng, index?) {
		console.log('lng, index', lng, index);
		if (index != undefined) {
			this.availableLangs.forEach((v, i) => {
				if (i == index) {
					v.selected = true;
				} else {
					v.selected = false;
				}
			})
		}

		this.currentLang = lng;
		this.ls.setItem('currentLang', lng);
		this.translate.use(lng.code);
		this.translate.getTranslation(lng.code).subscribe((data) => {
			console.log(lng.code, ' all trans ', data);
			this.dataService.setData('translations', data);
		})
	}
	changeTheme(theme) {
		// this.themeService.changeTheme(theme);
	}
	toggleNotific() {
		this.notificPanel.toggle();
	}
	toggleSidenav() {
		if (this.layoutConf.sidebarStyle === 'closed') {
			return this.layout.publishLayoutChange({
				sidebarStyle: 'full'
			});
		}
		this.layout.publishLayoutChange({
			sidebarStyle: 'closed'
		});
	}

	toggleCollapse() {
		// compact --> full
		// if (this.layoutConf.sidebarStyle === 'compact') {
		//   return this.layout.publishLayoutChange({
		//     sidebarStyle: 'full',
		//     sidebarCompactToggle: false
		//   }, {transitionClass: true});
		// }

		// // * --> compact
		// this.layout.publishLayoutChange({
		//   sidebarStyle: 'compact',
		//   sidebarCompactToggle: true
		// }, {transitionClass: true});

	}

	onSearch(e) {
		//   console.log(e)
	}

	signout() {
		this.jwtAuth.setMock(false, true);
		setTimeout(() => {
			this.jwtAuth.signout();
		}, 500);
	}

	statusChange() {
		console.log('statusChange called', this.isOffline);
		this.ls.setItem('isOffline', this.isOffline);
		this.jwtAuth.setMock(this.isOffline);
	}

	checkAccess() {
		if (this.isProd) {
			return false;
		} else {
			// only for sahiya sathi
			if (this.userDetails && this.userDetails.attributes && this.userDetails.attributes.roleId == 5) {
				return true;
			} else {
				return false;
			}
		}
	}
	checkArroval(){
		interval(1000).subscribe(x =>{
			let batch = this.ls.getItem("batch")
			if(batch == "COMPLETE")
				return false;
			else
				return true;
		})
	}
}
