// export const environment = {
//   production: true,
// 	hostName: 'https://api.jharkhandasha.com',
// 	hostURL: 'https://api.jharkhandasha.com',
// 	apiURL: 'https://api.jharkhandasha.com/api'
// 
//};

export const environment = {
	production: true,
	  hostName: 'https://sima-nodedev.dhanushinfotech.com',
	  hostURL: 'https://sima-nodedev.dhanushinfotech.com',
	  apiURL: 'https://sima-nodedev.dhanushinfotech.com/api'
  };
