import { AfterViewInit, Component, OnInit } from '@angular/core';

let deferredPrompt: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, AfterViewInit {

	showSection = false;
  constructor() { }

  ngOnInit() {
  }

	ngAfterViewInit(){
		this.initPWA();
	}

	initPWA() {
		/* https://www.amitmerchant.com/adding-custom-install-button-in-progressive-web-apps/ */
		/* https://web.dev/customize-install/ */
		/* https://web.dev/promote-install/ */
		window.addEventListener('beforeinstallprompt', (e) => {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
				// true for mobile device
				this.showSection = true;
			} else {
				// false for not mobile device
				this.showSection = false;
				deferredPrompt ? deferredPrompt.prompt() : false;
			}
			console.log('install prompt', e);
			deferredPrompt = e;
		});
	}

	async checkInstall(){
		console.log('check install', deferredPrompt);
		if (deferredPrompt) {
			deferredPrompt.prompt();
			const { outcome } = await deferredPrompt.userChoice;
			console.log('outcome', outcome);
			if (outcome === 'accepted') {
				this.showSection = false;
				deferredPrompt = null;
			}
		}
	}

}
