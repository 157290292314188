<mat-toolbar class="topbar mat-bg-card">
  <!-- Sidenav toggle button -->
  <!-- <button
    *ngIf="layoutConf.sidebarStyle !== 'compact'"
    mat-icon-button
    id="sidenavToggle"
    (click)="toggleSidenav()"
    matTooltip="Toggle Hide/Open"
  >
    <mat-icon>menu</mat-icon>
  </button> -->
  <a [routerLink]="['/home']">
    <span>
      <img
        src="assets/imgs/logo-round.png"
        alt="LOGO"
        class="logo"
        [ngClass.lg]="{'desktop': true}"
      />
    </span>
  </a>

  <span fxFlex></span>
  <div style="font-size: 20px;" *ngIf="checkApproval">
    <mat-progress-spinner
      class="example-margin"
      [color]="color"
      [mode]="mode"
      [value]="value"
      [diameter]="40"
      [matTooltip]="'approvalProgress' | translate"
    >
    </mat-progress-spinner>
  </div>
  <!-- Language Switcher -->
  <mat-slide-toggle
    *ngIf="checkAccess()"
    class="offline-status"
    [(ngModel)]="isOffline"
    [matTooltip]="'changeStatus' | translate"
    (change)="statusChange()"
  ></mat-slide-toggle>
  <button
    mat-button
    [matMenuTriggerFor]="menu"
    [matTooltip]="'changeLanguage' | translate"
  >
    <span class="material-icons"> translate </span>
    <!-- <span class="flag-icon {{currentLang.flag}} mr-8"></span> -->
    <span>{{currentLang.name}}</span>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      [class.selected]="lang.selected"
      *ngFor="let lang of availableLangs; let i= index;"
      (click)="setLang(lang,i)"
    >
      <!-- <span class="flag-icon mr-8 {{lang.flag}}"></span> -->
      <span>{{lang.name}}</span>
    </button>
  </mat-menu>

  <!-- <egret-search-input-over placeholder="Country (e.g. US)" resultPage="/search">
  </egret-search-input-over> -->
  <!-- Open "views/search-view/result-page.component" to understand how to subscribe to input field value -->

  <!-- Notification toggle button -->
  <button
    mat-icon-button
    [matTooltip]="'notifications' | translate"
    (click)="toggleNotific()"
    [style.overflow]="'visible'"
    class="topbar-button-right"
    *ngIf="notify"
  >
    <mat-icon>notifications</mat-icon>
    <span class="notification-number mat-bg-warn"
      >{{notificationCount || 0}}</span
    >
  </button>
  <!-- Top left user menu -->
  <!-- [matMenuTriggerFor]="accountMenu" -->
  <!-- [routerLink]="['/home/profile']" -->
  <button
    *ngIf="userDetails && userDetails.attributes"
    mat-icon-button
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button"
    [matTooltip]="'myprofile' | translate"
  >
    <img
      [src]="image || 'assets/imgs/woman.png'"
      alt=""
    />
  </button>

  <mat-menu #accountMenu="matMenu">
    <button mat-menu-item [routerLink]="['/home/profile']">
      <mat-icon>account_box</mat-icon>
      <span>{{'profile' | translate}}</span>
    </button>
    <!-- <button mat-menu-item [routerLink]="['/profile/settings']">
      <mat-icon>settings</mat-icon>
      <span>Account Settings</span>
    </button> -->
    <!-- <button mat-menu-item>
      <mat-icon>notifications_off</mat-icon>
      <span>Disable alerts</span>
    </button> -->
    <button mat-menu-item (click)="signout()">
      <mat-icon>exit_to_app</mat-icon>
      <span>{{'signout' | translate}}</span>
    </button>
  </mat-menu>
  <!-- <button mat-icon-button matTooltip="Sign Out" class="topbar-button-right" (click)="jwtAuth.signout()">
		<mat-icon>exit_to_app</mat-icon>
	</button> -->
</mat-toolbar>
