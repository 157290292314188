import { environment } from 'environments/environment';

export const AppConfig = {
	host: 'localhost',
	interval: {
		notification: environment.production ? 500000 : 600000
	},
	fetchInterval : 18000000,
	table: {
		limit: 8
	}
};

// https://github.com/davidmerfield/randomColor
export const COLORS = {
	'Submitted': '#2787ed',
	'Approved': '#1bcccd', // level1 approve
	'Completed': '#1bcd43', // level2 approve
	'Pending': '#d5d9d5',
	'Rejected': '#d14a4a',
	'RevertedforCorrection': '#2960b5',
	defaultPieColors: ['#1bcd43', '#d5d9d5']
};

export const ERRORS = {
	INVALIDVISIT: 'invalidVisit',
	INVALIDDATE: 'invalidDate',
	INVALIDDATERANGE: 'invalidDateRange',
	DATEDIFF: 'dateDiff',
	NODATA: 'noData',
	WRONG: 'wrong',
	DATAREFRESHED: 'dataRefreshed',
	FILLFIRST: 'fillfirst',
	NOTSCHEDULED: 'notScheduled',
	VISITSKIPPED: 'visitSkipped',
	SKIPPEDSUCCESS: 'skippedSuccess',
	FORMSUBMITTED: 'formSubmitted',
	FORMDRAFTED: 'formDrafted',
	INVALIDDATA: 'invalidData',
	APPROVENOTALLOWED: 'approveNotAllowed',
	REJECTNOTALLOWED: 'rejectNotAllowed',
	HVAPPROVED: 'hvApproved',
	HVREJECTED: 'hvRejected',
	INCENTIVEREJECTED: 'incentiveRejected',
	INCENTIVEAPPROVED: 'incentiveApproved',
	HVREVERTED: 'hvReverted',
	SELECTAPPROVEDATA: 'selectApproveData',
	SELECTHVAPPROVEDATA: 'selectHVApproveData',
	SELECTHVCHILDAPPROVEDATA: 'selectHVChildApproveData',
	SELECTHVSAHIYAAPPROVEDATA: 'selectHVSahiyaApproveData',
	SELECTHVREJECTDATA: 'selectHVRejectData',
	SELECTHVREVERTDATA: 'selectHVRevertData',
	INVALIDHVLIST: 'invalidHVList',
	INCOMPLETEFORM: 'incompleteForm',
	PLAAPPROVED: 'plaApproved',
	PLAREVERTED: 'plaReverted',
	PLAREJECTED: 'plaRejected',
	SELECTPLAAPPROVEDATA: 'selectPLAApproveData',
	SELECTPLAREJECTDATA: 'selectPLARejectData',
	SELECTPLAREVERTDATA: 'selectPLARevertData',
	INVALIDMEETING: 'invalidMeeting',
	ENTERWEIGHT: 'enterWeight',
	ENTERNAME: 'enterName',
	DATAUPDATED: 'dataUpdated',
	UNIQUECODE: 'uniqueCode',
	MAXWEIGHT: 'maxWeight',
	RELOGIN: 'relogin',
	SELECTREASON: 'selectReason',
	ALREADYREGISTERED: 'alreadyRegistered',
	NOREPORT: 'noReport',
	ONLINE: 'online',
	OFFLINE: 'offline',
	OFFLINEACTION: 'offlineAction',
	NODATALOCAL: 'noDataLocal',
	NOCHILDLIST: 'noChildList',
	TWINCHILDNAME: 'twinChildName',
	STARTYEAR : 'startyear',
	ENDYEAR : 'endyear',
	UPLOAD : "uploadSuccess",
	INCENTIVESUCCESS : "incentiveSuccess", 
	INCENTIVEUPDATE : "incentiveUpdate",
	INCENTIVEEXISTS : "incentiveExists", 
	SELECTENTRYINCENTIVEAPPROVEDATA: 'selectEntryIncentiveApproveData',
	SELECTSAHIYAINCENTIVEAPPROVEDATA: 'selectSahiyaIncentiveApproveData',
	SELECTENTRYREJECTDATA : "selectEntryRejectData",
	SELECTSAHIYAREJECTDATA : "selectSahiyaRejectData",
	DOWNLOADPROCEES : "downloadProcess",
	DOWNLOADTYPE:"downloadType",
	SWASTHSUCCESS:"swasthSucess",
	SWASTHUPDATE:"swasthUpdate",
	SWASTHEXISTS:"swasthExists"
};
