import { environment } from 'environments/environment.prod';

const APIURL = environment.apiURL;
const HOSTURL = environment.hostURL;


export const ApiConfig = {
	hostUrl: HOSTURL,
	apiUrl: APIURL,
	session: {
		signIn: APIURL + '/authenticate'
	},
	user: {
		getInfo: APIURL + '/user',
		getGeoInfo: APIURL + '/pid/1/geo',
		getNotification: APIURL + '/pid/1/notification',
		refreshData: APIURL + '/manualReportRefresh',
		user : APIURL + '/pid/1/user',
	},
	sahiya: {
		getList: APIURL + '/pid/1/facilitators'
	},
	pla: {
		getForm: APIURL + '/pid/1/plaeventform',
		submitForm: APIURL + '/pid/1/plaeventform',
		action: APIURL + '/pid/1/plaeventform',
		report: APIURL + '/pid/1/report/pla',
		gis: APIURL + '/pid/1/report/pla'
	},
	newChild: {
		getForm: APIURL + '/pid/1/plaeventform',
		submitForm: APIURL + '/pid/1/regchild',
		anganwadi: APIURL + '/pid/1/anganwadi',
		getList: APIURL + '/pid/1/regchild',
		getFormData: APIURL + '/pid/1/regchild',
		gis: APIURL + '/pid/1/report/pla'
	},
	homeVisit: {
		getList: APIURL + '/pid/1/homevisit',
		submitForm: APIURL + '/pid/1/homevisit',
		getFormData: APIURL + '/pid/1/homevisit',
		action: APIURL + '/pid/1/homevisit',
		getAllChildData: APIURL + '/pid/1/homevisit',
		visitList: APIURL + '/pid/1/report/pla',
		approve: APIURL + '/pid/1/homevisit',
		gis: APIURL + '/pid/1/report/pla'
	},
	team : {
		getTeam : APIURL + '/pid/1/team',
		submitFormTeam : APIURL + '/pid/1/team',
		deleteTeam : APIURL + '/pid/1/team'
	},
	budget : { 
		getBudget : APIURL + '/pid/1/report/pla',
		updateBudget : APIURL + '/pid/1/report/pla'
	},
	oneLink : { 
		pla : HOSTURL + "/getPlaReport",
		hv: HOSTURL + "/getHvReport",
		child : HOSTURL + "/getChildReport"
	},
	sahiyaReport : { 
		getList : APIURL + '/pid/1/report/pla',
		uploadSahiya : APIURL + '/pid/1/report/pla',
		approve : APIURL + '/pid/1/report/pla',
		uploadImage : APIURL + '/pid/1/report/pla',
		getImageUrl : APIURL + '/pid/1/report/pla',
		reportDownload : APIURL + '/pid/1/report/pla',
		deleteAnganwadi : APIURL + '/pid/1/report/pla'
	},
	public : { 
		getOngoing : APIURL + '/pid/1/public',
		createOngoing : APIURL + '/pid/1/public',
		gallery : APIURL + '/pid/1/public'
	},
	incentive : { 
		getSahiyaList : APIURL + '/pid/1/incentive',
		editSahiyaList : APIURL + '/pid/1/incentive',
		addSahiyaIncentive : APIURL + '/pid/1/incentive',
		approve : APIURL + '/pid/1/incentive',
	},
	monthly : { 
		monthly : APIURL + '/pid/1/monthly'
	},
	approval: APIURL+ '/pid/1/report/pla',
	grievence : APIURL + '/pid/1/grievence',
	swasth : APIURL + '/pid/1/swasth'
	
};