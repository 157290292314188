import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router, NavigationEnd } from '@angular/router';
import { ApiConfig } from 'app/configs/api.config';
import { AppConfig, ERRORS } from 'app/configs/app.config';
import { AppLoaderService } from 'app/shared/services/app-loader/app-loader.service';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { DataService } from 'app/shared/services/data.service';
import { HttpService } from 'app/shared/services/http.service';
import { LocalStoreService } from 'app/shared/services/local-store.service';
import { UtilService } from 'app/shared/services/util.service';

@Component({
	selector: 'app-notifications',
	templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit, OnDestroy {
	@Input() notificPanel;
	showMore: any = false;
	notifications: any = {};
	userDetails: any = {};
	subNotifications: any = {};
	selectedProgram = '';
	interval: any;
	subs: any = [];
	userOnline = true;
	intervalSet = false;
	noData = false;

	constructor(private router: Router, private jwtAuth: JwtAuthService, private http: HttpService,
		private dataService: DataService,
		private loader: AppLoaderService,
		private utility: UtilService,
		private ls: LocalStoreService
	) {
	}

	checkOnline() {
		if (this.userOnline && !this.intervalSet) {
			this.setCountCheck();
		} else {
			this.clearCountCheck();
		}
	}

	ngOnInit() {
		this.jwtAuth.setNotification(0)
		this.router.events.subscribe((routeChange) => {
			if (routeChange instanceof NavigationEnd) {
				this.notificPanel.close();
			}
		});

		this.userDetails = this.jwtAuth.getUser();
		console.log('userDetails', this.userDetails);

		this.getNotificationCount();

		this.userOnline = this.jwtAuth.isUserOnline();
		if (this.userOnline) {
			this.setCountCheck();
		}

		let aSub: any = this.jwtAuth.onStatusChange.subscribe((data: any) => {
			console.log('active status changed', data);
			if (data) {
				this.userOnline = data.status;
				this.checkOnline();
			}
		});

		let dsub: any = this.dataService.onDataReceived.subscribe((data: any) => {
			console.log('data receieved', data);
			if (data.type == 'refresh') {
				this.getNotificationCount();
			}
		});
		this.subs.push(aSub);
		this.subs.push(dsub);

		this.notificPanel.closedStart.subscribe((data: any) => {
			console.log('panel closed', data);
			this.reset();
		});
	}

	setCountCheck() {
		if (!this.intervalSet) {
			this.intervalSet = true;
			this.interval = setInterval(() => {
				this.getNotificationCount();
			}, AppConfig.interval.notification);
		} else {
			console.warn('already set');
		}
	}

	clearCountCheck() {
		this.intervalSet = false;
		clearInterval(this.interval);
	}

	ngOnDestroy() {
		if (this.subs && this.subs.length) {
			this.subs.forEach(sub => {
				sub.unsubscribe();
			});
		}
		this.subs = [];
		clearInterval(this.interval);
	}

	refershNotificationCount() {
		if (!this.loader.dialogRef) {
			this.loader.open();
		}
		if (this.jwtAuth.isUserOnline()) {
			this.http.get(ApiConfig.user.getNotification, { action: 'getNotification' }).subscribe((respData: any) => {
				this.loader.close();
				// respData = { "notifData": { "topicId": 1, "notificationId": null, "totalCount": 27, "programs": { "pla": { "totalCount": 27, "dataEntry": 0, "rejectedDataEntry": 1, "approval": 1 }, "homeVisit": { "totalCount": null, "dataEntry": null, "rejectedDataEntry": null, "approval": null } } } };
				console.log('respData', respData);
				// this.notifications = respData.notifData;
				this.ls.setItem("batch",respData.batch);
				this.ls.setItem("hvUpdate",respData.hvBatch);
				this.ls.setItem('notificationCount', respData);
				this.notifications = respData && respData.notifData ? respData.notifData.programs : {};
				
				if (this.notifications['homeVisit'] && this.notifications['homeVisit'].totalCount) {
					this.noData = false;
				} else {
					if (this.notifications['pla'] && this.notifications['pla'].totalCount) {
						this.noData = false;
					} else {
						this.noData = true;
					}
				}
				if(this.notifications['hvCount'] && this.notifications['hvCount'].totalCount) {
					this.noData = false;
				}
				if(this.notifications['incentive'] && this.notifications['incentive'].totalCount){
					this.noData = false;
				}
				// else {
				// 	this.noData = true;
				// }
				if(this.notifications['plaCount'] && this.notifications['plaCount'].totalCount) {
					this.noData = false;
				}
				if(this.notifications['sahiya'] && this.notifications['sahiya'].totalCount){
					this.noData = false;
				}
				if(this.notifications['newSahiya'] && this.notifications['newSahiya'].totalCount){
					this.noData = false;
				}
				if(this.notifications['clusterMonth'] && this.notifications['clusterMonth'].totalCount){
					this.noData = false;
				}
				if(this.notifications['blockMonth'] && this.notifications['blockMonth'].totalCount){
					this.noData = false;
				}
				if(this.notifications['districtMonth'] && this.notifications['districtMonth'].totalCount){
					this.noData = false;
				}
				// else {
				// 	this.noData = true;
				// }
				// delete this.notifications.homeVisit;
				this.jwtAuth.setNotification(respData && respData.notifData ? respData.notifData.totalCount : 0);

			}, (err) => {
				console.log('err', err);
				this.loader.close();
				// if (this.jwtAuth.isUserOnline()) {
				// 	this.utility.showToast(ERRORS.RELOGIN, 'toast-error');
				// 	this.jwtAuth.clear();
				// 	this.router.navigateByUrl('/sessions/signin');
				// }
			});
		} else {
			this.clearCountCheck();

			// use local data
			let respData = this.ls.getItem('notificationCount');
			this.notifications = respData && respData.notifData ? respData.notifData.programs : {};
			// delete this.notifications.homeVisit;
			this.jwtAuth.setNotification(respData && respData.notifData ? respData.notifData.totalCount : 0);
		}
	}

	getNotificationCount() {
		if (this.jwtAuth.isUserOnline()) {
			this.http.get(ApiConfig.user.getNotification, { action: 'getNotification' }).subscribe((respData: any) => {
				// respData = { "notifData": { "topicId": 1, "notificationId": null, "totalCount": 27, "programs": { "pla": { "totalCount": 27, "dataEntry": 0, "rejectedDataEntry": 1, "approval": 1 }, "homeVisit": { "totalCount": null, "dataEntry": null, "rejectedDataEntry": null, "approval": null } } } };
				// console.log('respData', respData);
				// this.notifications = respData.notifData;
				this.ls.setItem('notificationCount', respData);
				this.ls.setItem("hvUpdate",respData.hvBatch);
				if(respData.batch){
					this.ls.setItem("batch",respData.batch);
				}
				this.notifications = respData && respData.notifData ? respData.notifData.programs : {};

				if (this.notifications['homeVisit'] && this.notifications['homeVisit'].totalCount ) {
					this.noData = false;
				} 
				else {
					if (this.notifications['pla'] && this.notifications['pla'].totalCount ) {
						this.noData = false;
					} else {
						this.noData = true;
					}
				}
				if(this.notifications['hvCount'] && this.notifications['hvCount'].totalCount ) {
					this.noData = false;
				}
				if(this.notifications['sahiya'] && this.notifications['sahiya'].totalCount){
					this.noData = false;
				}
				if(this.notifications['incentive'] && this.notifications['incentive'].totalCount){
					this.noData = false;
				}
				if(this.notifications['block'] && this.notifications['block'].totalCount){
					this.noData = false;
				}
				if(this.notifications['newSahiya'] && this.notifications['newSahiya'].totalCount){
					this.noData = false;
				}
				if(this.notifications['clusterMonth'] && this.notifications['clusterMonth'].totalCount){
					this.noData = false;
				}
				if(this.notifications['blockMonth'] && this.notifications['blockMonth'].totalCount){
					this.noData = false;
				}
				if(this.notifications['districtMonth'] && this.notifications['districtMonth'].totalCount){
					this.noData = false;
				}
				// else {
				// 	this.noData = true;
				// }
				if(this.notifications['plaCount'] && this.notifications['plaCount'].totalCount ) {
					this.noData = false;
				}
				// else {
				// 	this.noData = true;
				// }
				// delete this.notifications.homeVisit;
				this.jwtAuth.setNotification(respData && respData.notifData ? respData.notifData.totalCount : 0);

			}, (err) => {
				console.log('err', err);
				// if (this.jwtAuth.isUserOnline()) {
				// 	this.utility.showToast(ERRORS.RELOGIN, 'toast-error');
				// 	this.jwtAuth.clear();
				// 	this.router.navigateByUrl('/sessions/signin');
				// }
			});
		} else {
			this.clearCountCheck();

			// use local data
			let respData = this.ls.getItem('notificationCount');
			this.notifications = respData && respData.notifData ? respData.notifData.programs : {};
			// delete this.notifications.homeVisit;
			this.jwtAuth.setNotification(respData && respData.notifData ? respData.notifData.totalCount : 0);
		}
	}

	clearAll(e) {
		e.preventDefault();
		this.notifications = {};
	}

	showDetails(program, name) {
		console.log(name)
		const arr = Object.keys(program);
		const obj = {};
		for (let i = 0; i < arr.length; i++) {
			if ((arr[i]) != 'totalCount') {
				obj[arr[i]] = program[arr[i]] || 0;
			}
		}
		this.subNotifications = obj;
		this.selectedProgram = name;
		this.showMore = true;
		if(name == 'hvCount' || name == 'plaCount'){
			this.showMore = false;
		}
	}

	goBack() {
		this.showMore = false;
	}

	navigate(key, value) {
		console.log('navigate', key);
		if (value) {
			if (key == 'rejectedDataEntry') {
				if (this.selectedProgram == 'homeVisit') {
					this.dataService.setData('showHVRejectList', true);
					this.router.navigateByUrl('/home/home-visit/existing-child-details-reject');
				} else {
					this.dataService.setData('showPLARejectList', true);
					this.router.navigateByUrl('/home/pla/mis-data-reject');
				}
			}

			else if (key == 'approval') {
				if (this.userDetails.attributes.roleId && this.userDetails.attributes.roleId <= 4) {
					if (this.selectedProgram == 'homeVisit') {
						this.reset();
						this.router.navigateByUrl('/home/home-visit/existing-child-details-approval');
					}
					else if(this.selectedProgram == 'pla') {
						this.reset();
						this.router.navigateByUrl('/home/pla/mis-data-approval');
					}
					else  if(this.selectedProgram == 'sahiya'){
						this.reset();
						this.router.navigateByUrl('/home/team-approval');
					}
					else  if(this.selectedProgram == 'incentive'){
						this.reset();
						this.router.navigateByUrl('/home/incentive/incentive-entry-approval');
					} 
					else if(this.selectedProgram == 'block'){
						this.reset();
						this.router.navigateByUrl('/home/block-approval');
					}
					else if(this.selectedProgram == 'newSahiya'){
						this.reset();
						this.router.navigateByUrl('/home/team-management/new-team-approval');
					}
					else if(this.selectedProgram == 'clusterMonth'){
						this.reset();
						this.router.navigateByUrl('/home/monthly-report/cluster-monthly-report-approval');
					}
					else if(this.selectedProgram == 'blockMonth'){
						this.reset();
						this.router.navigateByUrl('/home/monthly-report/block-monthly-report-approval');
					}
					else if(this.selectedProgram == 'districtMonth'){
						this.reset();
						this.router.navigateByUrl('/home/monthly-report/district-monthly-report-approval');
					}
				} else {
					if (this.selectedProgram == 'homeVisit') {
						this.reset();
						this.router.navigateByUrl('/home/home-visit/existing-child-details');
					} else if(this.selectedProgram == 'pla') {
						this.router.navigateByUrl('/home/pla/mis-data-approval');
					}
					else {
						this.router.navigateByUrl('/home/team');
					}
				}
			} else if (key == 'review') {
				if (this.selectedProgram == 'homeVisit') {
					this.reset();
					this.router.navigateByUrl('/home/existing-child-details-review');
				} else if(this.selectedProgram == 'pla') {
					this.reset();
					this.router.navigateByUrl('/home/pla/mis-data-approval');
				}
				else {
					this.reset();
					this.router.navigateByUrl('/home/team');
				}
			} else {
				if (this.selectedProgram == 'homeVisit') {
					this.reset();
					this.router.navigateByUrl('/home/home-visit/existing-child-details');
				} else if(this.selectedProgram == 'pla') {
					this.reset();
					this.router.navigateByUrl('/home/pla/mis-data-approval');
				}
				else {
					this.reset();
					this.router.navigateByUrl('/home/team');
				}
			}
		}
	}

	reset() {
		this.selectedProgram = '';
		this.showMore = false;
	}
}
